export const Modal = ({ onCancel, onDelete }) => {
  return (
    <div id="id01" className="modal">
      <span
        onclick="document.getElementById('id01').style.display='none'"
        className="close"
        title="Close Modal"
        onClick={onCancel}
      >
        ×
      </span>
      <form className="modal-content" action="/action_page.php">
        <div className="modal-container">
          <h1>Delete Blog</h1>
          <p style={{ margin: 10 }}>Are you sure you want to delete this blog?</p>

          <div className="clearfix">
            <button
              type="button"
              class="btn btn-light"
              style={{ border: "1px solid", margin: "25px 10px" }}
              onClick={onCancel}
            >
              Cancel
            </button>
            <button type="button" class="btn btn-danger" style={{ margin:  "25px 10px" }} onClick={onDelete}>
              Delete
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Modal;
