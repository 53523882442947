import { routes } from "./router/routeConfig";
import Router from "./router/router";
import "./styles/globalStyles.css";
import { ToastContainer } from "react-toastify";
import { useEffect } from "react";

const App = () => {
  useEffect(() => {
    console.log("cndsjb", window.location.pathname);
    if (
      !localStorage.getItem("isAdminLogin") &&
      window.location.pathname !== "/"
    )
      window.location.pathname = "/";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ToastContainer />
      <Router routes={routes} />
    </>
  );
};

export default App;
