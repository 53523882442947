import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from "../components/Header";

const AppRouter = ({ routes }) => {
  return (
    <Router>
      <Switch>
        {routes.map((route, index) => (
          <RouteWithSubRoutes key={index} {...route} />
        ))}
      </Switch>
    </Router>
  );
};

function RouteWithSubRoutes(route) {
  return (
    <>
      <Header />
      <div style={{ minHeight: "100vh" }}>
        <Route
          exact={route.exact}
          path={route.path}
          render={(props) => (
            <route.component {...props} routes={route.routes} />
          )}
        />
      </div>
    </>
  );
}

export default AppRouter;
