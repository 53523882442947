import Login from '../pages/Login';
import AddBlog from '../pages/AddBlog';
import Blogs from '../pages/Blogs';
import Blog from '../pages/Blog';

export const routes = [
  {
    path: "/",
    exact: true,
    component: Login,
  },
  {
    path: "/add-blog",
    exact: true,
    component: AddBlog,
  },
  {
    path: "/edit-blog/:id",
    exact: true,
    component: AddBlog,
  },
  {
    path: "/blogs",
    exact: true,
    component: Blogs,
  },
  {
    path: "/blog/:id",
    exact: true,
    component: Blog,
  },
];