import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../../constants/api";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { EditorState, AtomicBlockUtils, convertToRaw, ContentState } from "draft-js";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from "react-draft-wysiwyg";
import { uploadFile } from "./services/upload";
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from "draftjs-to-html";

const AddBlog = () => {
  const history = useHistory();
  const location = useLocation();
  const [values, setValues] = useState({
    title: "",
    authName: "",
    feature: false,
  });
  const editableBlog = useSelector((state) => state.blog?.editableBlog?.payload);
  const [blogContent, setBlogContent] = useState(EditorState.createEmpty());

  const loadImage = (file) =>
    new Promise((resolve) => {
      const image = new Image();
      image.onload = () => {
        const height = image.height;
        const width = image.width;
        resolve({ src: image.src, width, height });
      };
      image.src = URL.createObjectURL(file);
    });
  const uploadImageCallBack = async (file) => {
    const img = await loadImage(file);
    // const imageObject = {
    //   file: file,
    //   localSrc: img.src,
    // };
    const { data } = await uploadFile(file);
    return new Promise((resolve, reject) => {
      console.log("imageUrl", data?.imageUrl)
      resolve({ data: { link: data?.imageUrl, metadata: img } });
    });
  };

  const handleEditorDrop = async (e) => {
    e.preventDefault();
    if (e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0];
      if (file.type.startsWith("image")) {
        const newFile = await uploadImageCallBack(file);
        const entityData = {
          src: newFile.data.link,
          height: newFile.data?.metadata?.height || "320px",
          width: newFile.data?.metadata?.width || "320px",
        };
        const entityKey = blogContent
          .getCurrentContent()
          .createEntity("IMAGE", "MUTABLE", entityData)
          .getLastCreatedEntityKey();
        const newEditorState = AtomicBlockUtils.insertAtomicBlock(
          blogContent,
          entityKey,
          " "
        );
        setBlogContent(newEditorState);
      }
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("isAdminLogin")) history.push("/");
    if (editableBlog) {
      setValues({
        title: editableBlog.title,
        authName: editableBlog.authName,
        feature: editableBlog.feature,
      });
      const contentBlock = htmlToDraft(editableBlog?.content);
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      setBlogContent(EditorState.createWithContent(contentState));
    }
  }, [history, editableBlog]);

  const onBlogContentChange = (editorContent) => {
    setBlogContent(editorContent);
    console.log(
      "editorContent", draftToHtml(convertToRaw(editorContent.getCurrentContent()))
    );
  };

  const onSubmit = async () => {
    toast.dismiss();;
    if (location?.pathname?.includes("edit")) {
      await axios
        .patch(`${BASE_URL}/blog/${editableBlog?._id}`, {
          ...values,
          content: draftToHtml(convertToRaw(blogContent.getCurrentContent())),
          images: "",
        })
        .then((response) => {
          if (response.data) {
            toast.success("Blog is successfully updated on the website!");
            history.push('/blogs');
          }
        })
        .catch((err) => toast.error("Failed to update blog content."));
    } else {
      await axios
        .post(`${BASE_URL}/blog`, {
          ...values,
          content: draftToHtml(convertToRaw(blogContent.getCurrentContent())),
          images: "",
        })
        .then((response) => {
          if (response.data) {
            toast.success("Blog is successfully added to the website!");
            history.push('/blogs');
          }
        })
        .catch((err) => toast.error("Failed to submit blog content."));
    }
  };

  return (
    <div
      className="styled-page-container"
      style={{ marginTop: 58 }}
      onDrop={handleEditorDrop}
    >
      <p className="blog-main-heading" style={{ height: 0, margin: 24 }}>
        {location.pathname?.includes("edit") ? "Edit Blog" : "Add New Blog"}
      </p>
      <div className="blog-form">
        <input
          placeholder="Add a concise title of your blog here"
          value={values.title}
          onChange={(e) => setValues({ ...values, title: e.target.value })}
        />
        <input
          placeholder="Author Name"
          value={values.authName}
          onChange={(e) => setValues({ ...values, authName: e.target.value })}
        />
        <div className="featured-container">
          <span style={{ color: "rgb(0,0,0", fontSize: 17 }}>
            Do you want to feature this blog?
          </span>
          <label className="switch">
            <input
              type="checkbox"
              checked={values.feature}
              onChange={(e) =>
                setValues({ ...values, feature: e.target.checked })
              }
            />
            <span className="slider round"></span>
          </label>
        </div>
        <Editor
          editorState={blogContent}
          wrapperClassName="demo-wrapper"
          wrapperStyle={{ color: "rgb(25,25,25)" }}
          editorStyle={{
            background: "#fff",
            color: "rgb(25,25,25)",
            padding: 20,
            minHeight: "60vh",
          }}
          onEditorStateChange={onBlogContentChange}
        />
        <input type="submit" onClick={onSubmit}></input>
      </div>
    </div>
  );
};
export default AddBlog;
