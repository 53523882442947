import sanitizeHtml from "sanitize-html";

export const sanitizeHTML = (text, allowedTags) => {
  if (!text) return;
  return sanitizeHtml(text.split("\n").join("<br/>"), {
    allowedTags: allowedTags ?? [
      "b",
      "i",
      "strong",
      "em",
      "ul",
      "li",
      "p",
      "ol",
      "br",
    ],
  });
};

export const isValidUrl = (url) => {
  if (url?.includes("http") || url?.includes("http")) return true;
  else return false;
}