import React, { useState } from "react";
import { useHistory } from "react-router-dom";

const Login = () => {
  const history = useHistory();
  const [inputUsername, setInputUsername] = useState("");
  const [inputPassword, setInputPassword] = useState("");
  const [error, setError] = useState(null);

  const login = () => {
    if (inputUsername === "admin" && inputPassword === "admin") {
      localStorage.setItem("isAdminLogin", true);
    history.push("/blogs");
    } else {
      setError("Invalid credentials");
      localStorage.setItem("isAdminLogin", false);
    }
  };

  return (
    <div className="styled-page-container" style={{ marginTop: 58 }}>
      <p className="blog-main-heading" style={{ height: 0, margin: 24 }}>
        Login
      </p>
      <div className="blog-form">
        <input
          placeholder="Username"
          onChange={(e) => setInputUsername(e.target.value)}
        />
        <input
          placeholder="Password"
          type="password"
          onChange={(e) => setInputPassword(e.target.value)}
        />
        {error !== null ? <p style={{ color: "red" }}>{error}</p> : null}
        <input type="submit" onClick={login}></input>
      </div>
    </div>
  );
};
export default Login;
