import React, { useState } from "react";
import logo from "../assets/hhm_logo.png";
import { useHistory } from "react-router-dom";

const Header = () => {
  const history = useHistory();
  const [showDropDown, setShowDropDown] = useState(false);
  return (
    <>
      <div id="home">
        <nav
          className="navbar fixed-top navbar-expand-lg navbar-light bg-light"
          style={{
            backgroundColor: "white",
            justifyContent: "space-between",
          }}
        >
          <div>
            <img
              src={logo}
              height="40px"
              width="100px"
              className="hhm-logo"
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/")}
              alt=""
            />
          </div>
          <div className="menu">
            {localStorage.getItem("isAdminLogin") && (
              <ul>
                {/* <li>
                    <a style={{ color: "black", fontSize: 16 }} href="/blogs">
                      Stock Footage
                    </a>
                  </li> */}
                <li>
                  <a style={{ color: "black", fontSize: 16 }} href="/blogs">
                    Blogs
                  </a>
                </li>
                <li>
                  <button
                    type="button"
                    class="btn btn-warning"
                    onClick={() => {
                      localStorage.removeItem("isAdminLogin");
                      history.push("/");
                    }}
                  >
                    Sign Out
                  </button>
                </li>
              </ul>
            )}
          </div>
          <div className="mobileSidebar">
            <div
              className="navbar-toggler"
              style={{
                borderColor: "black",
                marginRight: "20px",
                cursor: "pointer",
              }}
              onClick={() => setShowDropDown(!showDropDown)}
            >
              <span
                className="navbar-toggler-icon"
                style={{ color: "black" }}
              ></span>
            </div>
          </div>
        </nav>
      </div>

      <div
        className="sidebarDropdownMenu"
        style={{
          width: showDropDown ? "100%" : "0px",
          height: showDropDown ? "100vh" : "0px",
          position: "absolute",
          // transform: showDropDown ? "translateX(80%)" : "",
          padding: showDropDown ? "20px 20px 20px 44px" : "0px",
        }}
      >
        {localStorage.getItem("isAdminLogin") && (
          <ul>
            {/* <li>
              <a style={{ color: "black", fontSize: 16 }} href="/blogs">
                Stock Footage
              </a>
            </li> */}
            <li>
              <a style={{ color: "black", fontSize: 16 }} href="/blogs">
                Blogs
              </a>
            </li>
          </ul>
        )}
      </div>
    </>
  );
};

export default Header;
