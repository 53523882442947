import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  editableBlog: null,
  deletedBlog: "",
};

export const blogSlice = createSlice({
  name: "quote",
  initialState,
  reducers: {
    setEditableBlog: (state, payload) => {
      state.editableBlog = payload;
    },
    setDeletedBlog: (state, payload) => {
      state.deletedBlog = payload;
    },
  },
});

export const { setEditableBlog, setDeletedBlog } = blogSlice.actions;

export default blogSlice.reducer;
