import React, { useEffect, useCallback, useState } from "react";
import { BlogCard } from "../components/blogCard";
import axios from "axios";
import { BASE_URL } from "../constants/api";
import { render } from "@testing-library/react";
import MiniLoader from "../components/miniLoader";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setDeletedBlog, setEditableBlog } from "../reducers/blogSlice"

export const Blogs = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const deletedBlog = useSelector((state) => state.blog.deletedBlog.payload);
  const [newBlogs, setNewBlogs] = useState([]);
  const [isloading, setIsLoading] = useState(true);
  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(1);
  const [totalBlogs, setTotalBlogs] = useState([]);

  const getBlogsData = useCallback(() => {
    axios
      .get(`${BASE_URL}/blog?limit=10&skip=${skip}&page=${page}`)
      .then((response) => {
        setIsLoading(false);
        setNewBlogs(response?.data);
        setTotalBlogs((totalBlogs) => [
          ...totalBlogs,
          ...response?.data?.blogs,
        ]);
      });
  }, [page, skip]);

  useEffect(() => {
    getBlogsData();
  }, [getBlogsData, skip]);

  useEffect(() => {
    if (deletedBlog !== "") {
      setIsLoading(true);
      setTotalBlogs((totalBlogs) =>
        totalBlogs.filter((blog) => blog._id !== deletedBlog)
      );
      dispatch(setDeletedBlog(""));
      setIsLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletedBlog, totalBlogs]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  render();
  if (isloading) {
    return (
      <div style={{ display: "flex", minHeight: 700, position: "relative" }}>
        <MiniLoader />
      </div>
    );
  } else {
    return (
      <>
        <div className="styled-page-container" style={{ marginTop: 58 }}>
          <div className="blogs-container">
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0px 16px"
              }}
            >
              <p className="blogs-heading">Blogs</p>
              <div
                className="view-more-white-btn"
                onClick={() => {
                  history.push("/add-blog");
                  dispatch(setEditableBlog(null))
                }}
              >
                Add New Blog
              </div>
            </div>
            <div className="blog-cards-container">
              {totalBlogs.map((blog, index) => (
                <BlogCard key={index} blog={blog} />
              ))}
            </div>
          </div>
          {(newBlogs?.currentPage !== newBlogs?.totalPages ||
            newBlogs?.totalPages === 0) && (
            <div
              className="view-more-white-btn"
              onClick={() => {
                setSkip(skip + 10);
                setPage(page + 1);
              }}
            >
              Load More
            </div>
          )}
        </div>
      </>
    );
  }
};

export default Blogs;
