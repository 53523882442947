import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../../../constants/api";

export const uploadFile = async (file) => {
  let formData = new FormData();
  formData.append("image", file);
  return await axios
    .post(
      `${BASE_URL}/blog/upload-image`,
      formData
    )
    .then((response) => {
      toast.success("Image uploaded successfully!")
      return response;
    })
    .catch((err) => {
      toast.error("Error occured while uploding");
    });
};
